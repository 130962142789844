@import "tailwindcss";

/* triggers frontend rebuilds */
@import "jit-refresh.css";

@theme {
  --color-brand-500: rgba(0, 174, 239, 1)
}

blockquote {
  color: #666666;
  font-family: georgia, serif;
  font-size: 1.6em;
  text-align: left;
}
blockquote:before {
  color: #999999;
  content: '\201C';
  font-size: 4em;
  float: left;
  line-height: 1em;
  margin-left: -3rem;
}
blockquote footer {
  background: inherit;
  font-size: 1rem;
  padding-top: 0;
  text-align: left;
  width: 100%;
}
blockquote p {
  margin-bottom: 0.3em;
}

body {
  color: #303030;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

footer {
  background: #ecf0f1;
  padding-bottom: 2em;
  padding-top: 2em;
  text-align: center
}

header nav {
  align-items: center;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  padding: 1em;
}

section {
  border-bottom: 1px solid #eeeeee;
  line-height: 1.6;
  margin-bottom: 4em;
  margin-top: 4em;
  text-align: center;
}

.avatar {
  border-radius: 100%;
  margin-right: 0.5em;
  overflow: hidden;
  vertical-align: middle;
}

.benefits {
  display: flex;
  margin-left: -1rem;
}

.benefit {
  text-align: left;
  margin: 1rem;
}

.benefit h2 {
  font-size: 1.6em;
}

.branding {
  font-weight: 600;
}
.branding img {
  vertical-align: middle;
}
@media only screen and (max-width: 450px) {
  .branding .branding-name {
    display: none;
  }
}

.button {
  background: #e74c3c;
  border: 0;
  border-bottom: 0.2em solid #c0392b;
  border-radius: 0.2em;
  color: #ffffff;
  font-weight: 600;
  padding: 0.5em 1em;
  text-decoration: none;
}
.button small {
  border-top: 1px solid #c0392b;
  font-size: 0.6em;
  padding-top: 0.4em;
}
.button:hover {
  background-color: #f75c4c;
}

.container {
  margin-left: 1em;
  margin-right: 1em;
  width: auto;
}
@media only screen and (min-width: 750px) {
  .container {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    width: 66%;
  }
}

.more-benefits {
  background-size: cover;
  background-position: 50% 50%;
  margin-bottom: 4rem;
  margin-top: 4rem;
  text-align: left
}
.more-benefits p {
  width: 50%;
}
.more-benefits {
  overflow: hidden;
}
.more-benefits h2 {
  font-weight: 600;
}
.more-benefits .benefit {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.more-benefits .benefit p {
  width: 100%;
}
@media only screen and (min-width: 750px) {
  .more-benefits .benefit:nth-child(odd) {
    float: left;
    width: 66%;
  }
  .more-benefits .benefit:nth-child(even) {
    float: right;
    width: 66%;
  }
}

.call-to-action {
  border: none;
}
.call-to-action .button {
  font-size: 1.6rem;
}

.hero {
  font-size: 1.96rem;
  text-align: center;
}
.hero figure {
  margin-left: -2em;
  margin-right: -2em;
}
.hero figure img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
}
.hero h1 {
  font-size: 1.6em;
  font-weight: 300;
  line-height: 1;
}
.hero strong {
  display: block;
  font-size: 1.6em;
  font-weight: 800;
  letter-spacing: -0.02em;
}
.hero p {
  color: #666666;
}
.hero .button {
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .hero .button {
    font-size: 1.2rem;
  }
  .hero .button small {
    font-size: 0.8em;
  }
  .hero .button small span {
    display: none;
  }
}

@media only screen and (max-width: 1000px) {
  .hero {
    padding-left: 1em;
    padding-right: 1em;
  }
  .hero figure {
    margin-left: -1em;
    margin-right: -1em;
  }
}
@media only screen and (max-width: 750px) {
  .hero {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 500px) {
  .hero {
  }
  .hero strong {
    font-size: 1em;
  }
}

.intro {
  padding-bottom: 4em;
}
.legalese {
  border-top: 1px solid #ccd0d1;
  margin-top: 1em;
  padding-top: 1em;
}

.nav-item {
  padding: 0.5em 0;
}

.nav-link {
  color: inherit;
  text-decoration: none;
}
.nav-link.signup {
  border: 0.2em solid #e74c3c;
  border-radius: 0.2em;
  display: inline-block;
  font-weight: 600;
  margin-left: 1em;
  padding: 0.5em 1em;
  text-decoration: none;
}
.nav-link.signup:hover {
  border-color: #f75c4c;
  color: #f75c4c;
}

.newsletter-signup .button {
  font-size: 1.6em;
}

.newsletter-signup .email {
  border-radius: 0.2em;
  border: 0.1em solid #dddddd;
  font-size: 1.6em;
  margin-bottom: 0.5em;
  padding: 0.5em;
  text-align: center;
  max-width: 20em;
  width: 66%;
}

.process {
  gap: 1.6em;
  margin-bottom: 4rem;
}
.process-step {
  flex: 1;
  text-align: left;
}
.process-step-number {
  font-size: 1.6em;
  font-weight: 600;
}
@media only screen and (min-width: 750px) {
  .process {
    display: flex;
  }
}
